import 'angular';
import 'ng-lodash';
import 'angular-cookies';
import '@uirouter/angularjs';
import 'angular-ui-router.statehelper';
import 'angular-sanitize';
import 'ng-facebook';
import 'angular-restmod';
import AMSApi from 'angular-restmod/dist/styles/ams';
import PagedModel from 'angular-restmod/dist/plugins/paged';
import 'angular-PubSub';
import 'angular-ui-bootstrap';
import 'angular-gettext';
import 'ng-infinite-scroll';
import 'angular-bootstrap-show-errors';
import 'angular-ellipsis';
import 'videogular';
import 'videogular-controls';
import '@musicdirector/videogular-scrubandselect';
import 'angular-social-links';
import 'angular-no-captcha';
import 'angular-loading-bar';
import 'angular-validation-match';
import 'angular-debounce';
import 'angular-local-storage';
import 'angular-growl-v2';
import 'ng-file-upload';
import 'ng-tags-input';
import 'angular-dragdrop';
import 'angular-drag-and-drop-lists';
import 'angular-google-analytics';
import 'angular-shims-placeholder';
import 'angularjs-slider';
import 'angular-scroll';
import 'scrollmonitor';
import '@musicdirector/angular-viewport-watch';
import 'jquery.isinview';
import 'angular-cache-buster';
import 'angular-bind-html-compile';
import 'angular-file-saver';
import '@musicdirector/angular-pretty-checkable';
import 'angular-http-auth';
import '@palmabit/angular-cookie-law';
import 'angular-dynamic-locale';
import 'query-string';
import 'angular-oauth2';

import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';

import './scripts/raven';
import './scripts/app';
import './scripts/constants';
import './scripts/directives';
import './scripts/filters';
import './scripts/services';
import './scripts/controllers';

import './styles/main.scss';
