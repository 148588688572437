import { config as applicationConfiguration } from './services';
import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

// Include Raven for non-dev builds
if (applicationConfiguration && applicationConfiguration.environment !== 'dev') {
  Sentry.init({
    dsn: 'https://9d9d11420d894cf39aa1eaf560afbd15@sentry.io/280789',
    release: applicationConfiguration.git.tag,
    integrations: [new AngularIntegration()],
  });
} else {
  // dummy setup, but we need the dependency
  Sentry.init({
    dsn: 'https://foobar@app.getsentry.com/1',
    captureUnhandledRejections: false,
    integrations: [new AngularIntegration()],
  });
}
